import React from "react";
import Header from "components/Header";



const TinyLayout = ({ children }) => {
  return (
    <main className="bg-schwurbel">
      <Header />
      <div className="flex flex-col gap-20 lg:gap-32">{children}</div>
      
    </main>
  );
};

export default TinyLayout;
