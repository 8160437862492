import React from "react";

export default function Header() {
  
  return (
    <header className="bg-schwurbel">
      <nav className="mx-auto flex flex-col sm:flex-row max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="w-auto">
          
          <a href="/" className="text-xl font-bold leading-6 text-gray-900">
            Friedrich Chennault
          </a>

        </div>
        
        <div className="w-auto">
                  
          <a href="tel:+4915510701124" className="text-xl font-semibold leading-6 mt-5 sm:mt-0 text-gray-900">Telefon</a>
          
        </div>
        <div className="w-auto">

          <a href="mailto:friedrich-chennault@web.de" className="text-xl font-semibold leading-6 mt-2 sm:mt-0 text-gray-900">E-Mail</a>
          
        </div>

        <div className="w-auto">

          
          <p className="text-xl font-semibold bg-blue-800 leading-6 mt-2 sm:mt-0 text-white">
            Nürnberg + 20km        
          </p>

        </div>

        
        
      </nav>
      
    </header>
  )
}
