import React from "react";

const Footer = () => {
  return (
    <footer id="footer" className="bg-schwurbel py-8 text-sm">
      <hr className="border-gray-200 sm:mx-auto" />
      <div className="mx-auto grid max-w-2xl grid-cols-1">
        <a href="/impressum" className="mt-2 mb-2 text-xl font-bold text-center tracking-tight text-gray-900 sm:text-2xl">
          Impressum
        </a>
      </div>
    
      
    </footer>
  );
};

export default Footer;
