import React, { lazy } from "react";
import { useRoutes } from "react-router-dom";
import { Navigate } from "react-router-dom";

import MainLayout from "layout/MainLayout";
import TinyLayout from "layout/TinyLayout";
const About = lazy(() => import("sections/About"));
const Impressum = lazy(() => import("sections/Impressum"));
const Page404 = lazy(() => import("sections/Page404"));

// ==============================|| ROUTINGs ||============================== //

export default function Routes() {
  return useRoutes([
    {
      path: "/",
      element: (
        <MainLayout>
          
          <About />
          
        </MainLayout>
      ),
    },

   
    {
      path: "/impressum",
      element: (
        <TinyLayout>
          <Impressum />
        </TinyLayout>
      ),
    },



    

    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
    {
      path: "/404",
      element: (
        <MainLayout>
          <Page404 />
        </MainLayout>
      ),
    },
  ]);
}
